<template>
    <div class="config">
        <div>个人资料</div>
        <div>
            <img :src="form.avatar">
        </div>
        <div>
            <div>账号</div>
            <div>{{ form.username }}</div>
        </div>
        <div>
            <div>昵称</div>
            <div>{{ form.nickname }}</div>
        </div>
        <div>
            <div>手机</div>
            <div>{{ form.mobile }}</div>
        </div>
        <div @click="close">退出登录</div>
    </div>
</template>

<script>
import store from "../store";

export default {
    name: "SettingConfig",
    data() {
        return {
            form: {
                hospital_name: '',
                second_name: '',
                logo: ''
            },
        }
    },
    mounted() {
        this.onDetail();
    },
    methods: {
        onDetail() {
            this.$axios.post('api/kuerp/order/user/userInfo', {}).then(res => {
                this.form = res.data.data.userinfo;
                this.$store.commit('setUserInfo', { data: res.data.data, status: true, username: res.data.data.userinfo.username, avatar: res.data.data.userinfo.avatar, is_vip: res.data.data.userinfo.is_vip });
            })
        },
        /**
         * 退出点击
         * */
        close() {
            this.$confirm('确定退出?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$axios.post('api/kuerp/order/user/logout', {
                    // token:this.$store.state.user.token
                }).then(res => {
                    this.$store.commit('setUserInfo', { data: '', status: false });
                    this.$router.push('/Login');
                    this.$message({
                        type: 'success',
                        message: '成功!'
                    });
                })

            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });
            });
        },
    },
}
</script>

<style lang="scss" scoped>
.config {
    height: 100%;
    padding: 0.24rem 0.28rem 0.3rem 0.28rem;
    position: relative;

    >div:nth-child(1) {
        font-size: 0.18rem;
        font-weight: 400;
        color: #333333;
        padding-bottom: 0.1rem;
        border-bottom: 0.01rem solid #E4E4E4;
    }

    >div:nth-child(2) {
        display: flex;
        align-items: center;
        padding: 0.29rem 0 0.15rem 0;
        border-bottom: 0.02rem dotted #E4E4E4;

        img {
            width: 0.7rem;
            height: 0.7rem;
            border-radius: 50%;
        }

        div {
            font-size: 0.18rem;
            font-weight: 500;
            color: #333333;
            margin-left: 0.25rem;
        }
    }

    >div:nth-child(3),
    >div:nth-child(4),
    >div:nth-child(5) {
        font-size: 0.14rem;
        font-weight: 400;
        color: #666666;
        padding: 0.19rem 0;
        border-bottom: 0.02rem dotted #E4E4E4;
        display: flex;
        align-items: center;

        >div:nth-child(2) {
            color: #333333;
            margin-left: 0.56rem;
        }
    }

    >div:nth-child(6) {
        width: 1.62rem;
        height: 0.52rem;
        background: #4B83F0;
        border-radius: 0.04rem;
        font-size: 0.14rem;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 0.52rem;
        text-align: center;
        cursor: pointer;
        position: absolute;
        bottom: 0.3rem;
        right: 0.28rem;
    }
}
</style>
